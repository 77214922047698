// Wait for window to be final resize
const waitForFinalEvent = (function () {
  const timers = {};
  return function (callback, ms, uniqueId) {
    if (!uniqueId) {
      uniqueId = Math.random();
    }
    if (timers[uniqueId]) {
      clearTimeout(timers[uniqueId]);
    }
    timers[uniqueId] = setTimeout(callback, ms);
  };
})();

// Set text based on window size
const SetText = function () {
  const PrimaryNavigationItemReading = document.getElementById('PrimaryNavigationItemReading');
  const PrimaryNavigationItemSpelling = document.getElementById('PrimaryNavigationItemSpelling');

  if (window.innerWidth <= 500) {
    PrimaryNavigationItemReading.innerHTML =
      PrimaryNavigationItemReading.getAttribute('data-text-r400');
    PrimaryNavigationItemSpelling.innerHTML =
      PrimaryNavigationItemSpelling.getAttribute('data-text-r400');
  } else if (window.innerWidth > 500 && window.innerWidth <= 1005) {
    PrimaryNavigationItemReading.innerHTML =
      PrimaryNavigationItemReading.getAttribute('data-text-original');
    PrimaryNavigationItemSpelling.innerHTML =
      PrimaryNavigationItemSpelling.getAttribute('data-text-original');
  } else if (window.innerWidth > 1005) {
    PrimaryNavigationItemReading.innerHTML =
      PrimaryNavigationItemReading.getAttribute('data-text-original');
    PrimaryNavigationItemSpelling.innerHTML =
      PrimaryNavigationItemSpelling.getAttribute('data-text-original');
  }
};
window.addEventListener(
  'resize',
  () => {
    waitForFinalEvent(
      () => {
        SetText();
        // VideoDynamic();
      },
      500,
      'waitForFinalWindowResize'
    );
  },
  false
);

function onDocumentReady() {
  SetText();
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', onDocumentReady);
} else {
  onDocumentReady();
}
