import { Printd } from 'printd';
import Tooltip from 'bootstrap/js/dist/tooltip';
import Modal from 'bootstrap/js/dist/modal';
import { SearchAutocomplete } from '@aalp/algolia-frontend-integration';
import { debounce } from 'debounce';

import './javascript_landingpage';

window.SearchAutocomplete = SearchAutocomplete;

/* Handle Sentry */

// Assume SDK is lazily loaded by the host (i.e. blog)

/* eslint-disable no-undef */
if (typeof Sentry !== 'undefined' && Sentry !== null && Sentry.onLoad) {
  Sentry.onLoad(() => {
    // define local client to not interfere with a host client
    const client = new Sentry.BrowserClient({
      dsn: process.env.SENTRY_DSN,
      release: process.env.SENTRY_RELEASE,
      environment: process.env.DEPLOY_ENVIRONMENT,

      enabled: process.env.NODE_ENV === 'production',

      transport: Sentry.makeFetchTransport,
      stackParser: Sentry.defaultStackParser,
      integrations: Sentry.defaultIntegrations,
    });
    // eslint-disable-next-line no-unused-vars
    const hub = new Sentry.Hub(client); // use this as regular Sentry locally if needed
  });
}
/* eslint-enable */

/* Helpers */

function fragmentFromString(strHTML) {
  return document.createRange().createContextualFragment(strHTML);
}

function delegate({ event: eventName = 'click', target = document, selector, fn } = {}) {
  target.addEventListener(
    eventName,
    (e) => {
      const trigger = e.target.closest(selector);

      if (trigger) {
        switch (eventName) {
          case 'mouseover':
            if (trigger.dataset.mouseovered !== 'true') {
              trigger.dataset.mouseovered = 'true';
              fn(e, trigger);
            }
            break;
          case 'mouseout':
            if (trigger.dataset.mouseovered === 'true' && !trigger.contains(e.relatedTarget)) {
              fn(e, trigger);
              delete trigger.dataset.mouseovered;
            }
            break;
          default:
            fn(e, trigger);
        }
      }
    },
    false
  );
}

// Change color of list bullets and numbers
const PrependSPANinLI = function () {
  [].slice.call(document.querySelectorAll('ul > li,ol > li')).forEach((elem) => {
    const existingHTML = elem.innerHTML;

    elem.innerHTML = `<span>${existingHTML}</span>`;
  });
};

// Add hasbtn class to anchor tags containing button DIVs
const ahasbtn = function () {
  [].slice.call(document.querySelectorAll('a div.btn')).forEach((elem) => {
    elem.closest('a').classList.add('hasbtn');
  });
};

// Replace all SVG images with inline SVG
const SVGtoInline = function () {
  [].slice.call(document.querySelectorAll('img.svg')).forEach((img) => {
    const imgID = img.id;
    const imgClass = img.className;
    const imgURL = img.src;

    fetch(imgURL)
      .then((response) => response.text())
      .then((data) => {
        // Get the SVG tag, ignore the rest
        const svg = fragmentFromString(data).querySelector('svg');
        // Add replaced image's ID to the new SVG
        if (imgID) {
          svg.id = imgID;
        }
        // Add replaced image's classes to the new SVG
        if (imgClass) {
          svg.setAttribute('class', `${imgClass} replaced-svg`);
        }
        svg.removeAttribute('xmlns');
        // Replace image with new SVG
        img.parentNode.replaceChild(svg, img);
      });
  });
};

// Make video iframes responsive
const videoContainerClass = 'video-container';

document.querySelectorAll(`.${videoContainerClass}`).forEach((el) => {
  const wrapper = document.createElement('div');
  const iframe = el.querySelector('iframe');

  wrapper.className = `${videoContainerClass}__wrap`;
  wrapper.append(...el.childNodes);
  el.appendChild(wrapper);

  if (!iframe) {
    return;
  }

  wrapper.style.paddingTop = `${(iframe.height / iframe.width) * 100}%`;
});

// FUNCTION TO GET AND AUTO PLAY YOUTUBE VIDEO FROM DATATAG
function autoPlayYouTubeModal() {
  delegate({
    event: 'click',
    selector: '[data-toggle="modal"]',
    fn(e, trigger) {
      const theModalSelector = trigger.dataset.bsTarget;
      const videoSRC = trigger.dataset.thevideo;
      const videoSRCauto = `${videoSRC}?autoplay=1&autoPlay=true`;

      document.querySelector(`${theModalSelector} iframe`).setAttribute('src', videoSRCauto);

      function onClose() {
        document.querySelector(`${theModalSelector} iframe`).setAttribute('src', videoSRC);
        document
          .querySelector(theModalSelector)
          .removeEventListener('hidden.bs.modal', onClose, false);
      }

      document.querySelector(theModalSelector).addEventListener('hidden.bs.modal', onClose, false);
    },
  });
}
function initTextareaAutoSize(selector) {
  function OnInput() {
    this.style.height = 'auto';
    this.style.height = `${this.scrollHeight}px`;
  }

  [].slice.call(document.querySelectorAll(selector)).forEach((tx) => {
    tx.setAttribute('style', `height:${tx.scrollHeight}px;overflow-y:hidden;`);
    tx.addEventListener('input', OnInput, false);
  });
}

function initCollapseCard() {
  const slideDown = (elem) => {
    elem.style.height = `${elem.scrollHeight}px`;
  };
  const slideUp = (elem) => {
    elem.style.height = 0;
  };
  const activeClass = 'active';

  function onSlideUp(e) {
    e.target.closest(`.${activeClass}`).classList.remove(activeClass);
    e.target.removeEventListener('transitionend', onSlideUp, false);
  }

  delegate({
    event: 'click',
    selector: '.collapse-card__heading, .collapse-card__close_handler',
    fn(e, trigger) {
      const card = trigger.closest('.collapse-card');
      const cardBody = card.querySelector('.collapse-card__body');

      if (card.classList.contains(activeClass)) {
        cardBody.addEventListener('transitionend', onSlideUp, false);
        slideUp(cardBody);
      } else {
        card.classList.add(activeClass);
        slideDown(cardBody);
      }
    },
  });
}

function initTooltips() {
  const tooltipTriggerSelector = '[data-bs-toggle="tooltip"]';
  const tooltipClass = 'js-tooltip';

  const options = {
    html: true,
    trigger: 'manual',
    customClass: tooltipClass,
    sanitize: false,
  };
  let showTid;
  let hideTid;
  const showDelay = 200;
  const hideDelay = 100;

  delegate({
    event: 'mouseover',
    selector: tooltipTriggerSelector,
    fn(e, trigger) {
      const tooltipApi = Tooltip.getInstance(trigger);

      clearTimeout(hideTid);
      showTid = setTimeout(() => {
        if (!tooltipApi.tip || tooltipApi.tip.getClientRects().length === 0) {
          tooltipApi.show();
        }
      }, showDelay);
    },
  });

  delegate({
    event: 'mouseout',
    selector: tooltipTriggerSelector,
    fn(e, trigger) {
      const tooltipApi = Tooltip.getInstance(trigger);

      clearTimeout(showTid);
      hideTid = setTimeout(() => {
        if (
          tooltipApi.tip &&
          e.relatedTarget !== tooltipApi.tip &&
          !tooltipApi.tip.contains(e.relatedTarget)
        ) {
          tooltipApi.hide();
        }
      }, hideDelay);
    },
  });

  delegate({
    event: 'mouseover',
    selector: `.${tooltipClass}`,
    fn() {
      clearTimeout(hideTid);
    },
  });

  delegate({
    event: 'mouseout',
    selector: `.${tooltipClass}`,
    fn(e, trigger) {
      const tooltipApi = Tooltip.getInstance(trigger);

      hideTid = setTimeout(() => {
        if (
          /* eslint-disable no-underscore-dangle */
          tooltipApi._element &&
          e.relatedTarget !== tooltipApi._element &&
          !tooltipApi._element.contains(e.relatedTarget)
          /* eslint-enable no-underscore-dangle */
        ) {
          tooltipApi.hide();
        }
      }, hideDelay);
    },
  });

  [].slice
    .call(document.querySelectorAll(tooltipTriggerSelector))
    .forEach((tooltipTriggerEl) => new Tooltip(tooltipTriggerEl, options));
}

const AlertClose = function () {
  const alertSelector = '.alert';

  function onHidden(e) {
    e.target.removeEventListener('transitionend', onHidden, false);
    e.target.parentNode.removeChild(e.target);
  }

  delegate({
    event: 'click',
    selector: `${alertSelector} button.close`,
    fn(e, trigger) {
      trigger.closest(alertSelector).addEventListener('transitionend', onHidden, false);
      trigger.closest(alertSelector).style.opacity = 0;
    },
  });
};

// Toggler
const Toggler = function () {
  function hideAllBut(selector) {
    const theOne = document.querySelector(selector);

    [].slice.call(document.querySelectorAll('.toggler')).forEach((toggler) => {
      toggler.hidden = true;
    });

    if (theOne) {
      theOne.hidden = false;
    }
  }

  hideAllBut('.togglerItem1');

  delegate({
    event: 'click',
    selector: '[data-toggle].btn-toggler',
    fn(e, trigger) {
      e.preventDefault(); // prevent navigating
      [].slice.call(trigger.parentNode.querySelectorAll('.btn')).forEach((elem) => {
        elem.classList.remove('active', 'btn-primary');
        elem.classList.add('btn-default');
      });
      trigger.classList.add('active', 'btn-primary');
      hideAllBut(trigger.dataset.bsToggle);
    },
  });
};

// var SetSecondaryNavigation = function () {
//   var parts = location.hostname.split('.');
//   var subdomain = parts.shift();
//   var upperleveldomain = parts.join('.');
//   var sndleveldomain = parts.slice(-2).join('.');
// };

const overflownKlass = 'is-overflown';
const overflownLeftKlass = `${overflownKlass}_left`;
const overflownRightKlass = `${overflownKlass}_right`;

function getOverflowX(element) {
  const { clientWidth, scrollWidth, scrollLeft } = element;
  const totalOverflow = scrollWidth - clientWidth;

  return {
    total: totalOverflow,
    left: scrollLeft,
    right: totalOverflow - scrollLeft,
  };
}

function monitor(elem) {
  const overflowX = getOverflowX(elem);
  const wrapper = elem.parentNode;

  wrapper.classList.toggle(overflownKlass, overflowX.total);
  wrapper.classList.toggle(overflownLeftKlass, overflowX.left);
  wrapper.classList.toggle(overflownRightKlass, overflowX.right);
}

function initResponsiveTables() {
  const tables = document.querySelectorAll('.table-responsive');

  function refresh() {
    tables.forEach((table) => monitor(table));
  }

  tables.forEach((table) => {
    table.addEventListener(
      'scroll',
      debounce(() => monitor(table), 200)
    );
    monitor(table);
  });

  window.addEventListener('resize', debounce(refresh, 50));
  window.addEventListener('orientationchange', debounce(refresh, 0));
}

// run code when DOM is ready
function onDocumentReady() {
  PrependSPANinLI();
  SVGtoInline();
  ahasbtn();
  AlertClose();
  Toggler();
  autoPlayYouTubeModal();
  initTooltips();
  // VideoDynamic();
  initTextareaAutoSize('textarea.js-auto-size');
  initCollapseCard();
  initResponsiveTables();

  delegate({
    event: 'mouseover',
    selector: '.hoverSwap',
    fn(e, trigger) {
      trigger.dataset.srcOriginal = trigger.getAttribute('src');
      trigger.setAttribute('src', trigger.dataset.srcHover);
      trigger.setAttribute('srcset', trigger.dataset.srcHover);
    },
  });
  delegate({
    event: 'mouseout',
    selector: '.hoverSwap',
    fn(e, trigger) {
      trigger.setAttribute('src', trigger.dataset.srcOriginal);
      trigger.setAttribute('srcset', trigger.dataset.srcOriginal);
    },
  });

  // Preload hoverSwap images
  [].slice.call(document.querySelectorAll('.hoverSwap')).forEach((elem) => {
    new Image().src = elem.dataset.srcHover;
  });

  [].slice.call(document.querySelectorAll('.print')).forEach((elem) => {
    elem.addEventListener(
      'click',
      () => {
        const d = new Printd();
        d.print(elem.closest('.printArea'));
      },
      false
    );
  });

  delegate({
    event: 'click',
    selector: '.toggleDisplay',
    fn(e, trigger) {
      if (trigger.matches('.showOnly')) {
        trigger.hidden = true;
      }
      if (trigger.matches('p, div')) {
        const txt = trigger.textContent;

        trigger.textContent = txt === 'Show More' ? 'Show Less' : 'Show More';
      }

      if (trigger.previousElementSibling.matches('.toggleContent')) {
        trigger.previousElementSibling.style.display = '';
      }
    },
  });

  // Inline Audio Player
  let audio;
  delegate({
    event: 'click',
    selector: '.playAudio',
    fn(e, trigger) {
      if (audio) {
        audio.pause();
      }
      audio = document.createElement('audio');
      audio.setAttribute('src', trigger.dataset.audio);
      audio.currentTime = 0;
      audio.play();
    },
  });

  // Escape key doesn't work in IE11 and Edge 12-18.
  // Include global (tiny) polyfill
  // https://caniuse.com/keyboardevent-key
  // https://github.com/shvaikalesh/shim-keyboard-event-key

  /* eslint-disable */
  (function () {
    if (!self.document) {
      return;
    }

    const event = KeyboardEvent.prototype;
    const desc = Object.getOwnPropertyDescriptor(event, 'key');
    if (!desc) {
      return;
    }

    const keys = {
      Win: 'Meta',
      Scroll: 'ScrollLock',
      Spacebar: ' ',

      Down: 'ArrowDown',
      Left: 'ArrowLeft',
      Right: 'ArrowRight',
      Up: 'ArrowUp',

      Del: 'Delete',
      Apps: 'ContextMenu',
      Esc: 'Escape',

      Multiply: '*',
      Add: '+',
      Subtract: '-',
      Decimal: '.',
      Divide: '/',
    };

    Object.defineProperty(event, 'key', {
      get() {
        const key = desc.get.call(this);

        return keys.hasOwnProperty(key) ? keys[key] : key;
      },
    });
  })();
  /* eslint-enable */

  // better modal tabbing
  function patchModalFocus() {
    // https://github.com/twbs/bootstrap/issues/28481#issuecomment-763017750
    const TAB_KEY = 9;
    const focusableSelectors = [
      'a[href]:not([tabindex^="-"])',
      'area[href]:not([tabindex^="-"])',
      'input:not([type="hidden"]):not([type="radio"]):not([disabled]):not([tabindex^="-"])',
      'input[type="radio"]:not([disabled]):not([tabindex^="-"]):checked',
      'select:not([disabled]):not([tabindex^="-"])',
      'textarea:not([disabled]):not([tabindex^="-"])',
      'button:not([disabled]):not([tabindex^="-"])',
      'iframe:not([tabindex^="-"])',
      'audio[controls]:not([tabindex^="-"])',
      'video[controls]:not([tabindex^="-"])',
      '[contenteditable]:not([tabindex^="-"])',
      '[tabindex]:not([tabindex^="-"])',
    ];

    function getFocusableChildren(node) {
      return [].filter.call(
        node.querySelectorAll(focusableSelectors.join(',')),
        (child) => !!(child.offsetWidth || child.offsetHeight || child.getClientRects().length)
      );
    }

    function trapTabKey(node, event) {
      const focusableChildren = getFocusableChildren(node);
      const focusedItemIndex = focusableChildren.indexOf(document.activeElement);

      if (event.shiftKey && focusedItemIndex === 0) {
        focusableChildren[focusableChildren.length - 1].focus();
        event.preventDefault();
      } else if (!event.shiftKey && focusedItemIndex === focusableChildren.length - 1) {
        focusableChildren[0].focus();
        event.preventDefault();
      }
    }

    function bindKeypress(event, modal) {
      if (modal && event.which === TAB_KEY) {
        trapTabKey(modal, event);
      }
    }

    const handler = {
      modal: null,
      handleEvent(e) {
        bindKeypress(e, this.modal);
      },
    };

    document.addEventListener('show.bs.modal', (evt) => {
      handler.modal = evt.target;
      document.addEventListener('keydown', handler);
    });
    document.addEventListener('hide.bs.modal', () => {
      handler.modal = null;
      document.removeEventListener('keydown', handler);
    });
  }

  patchModalFocus();

  // escape closing isn't working for a hard to find reason, brute force it
  document.addEventListener('keydown', (e) => {
    if (e.key !== 'Escape') {
      return;
    }

    const openedModal = document.querySelector('.modal.show');

    if (!openedModal) {
      return;
    }

    Modal.getInstance(openedModal).hide();
  });
  // make bootstrap v3 HTML work with bootstrap v5 JS
  function adaptBootstrapModals() {
    const attrNames = ['toggle', 'target', 'dismiss'];

    function attributify(attrName, elem) {
      const bootstrapPrefix = 'bs';
      const attrVal = elem.getAttribute(`data-${attrName}`);

      if (attrVal) {
        elem.setAttribute(`data-${bootstrapPrefix}-${attrName}`, attrVal);
      }
    }

    [].slice
      .call(
        document.querySelectorAll(
          attrNames.reduce(
            (accumulator, val, i) => `${accumulator + (i ? ', ' : '')}[data-${val}]`,
            ''
          )
        )
      )
      .forEach((elem) => {
        attrNames.forEach((attr) => {
          attributify(attr, elem);
        });
      });
  }

  adaptBootstrapModals();
}

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', onDocumentReady);
} else {
  onDocumentReady();
}

// live reload for dev mode
if (process.env.NODE_ENV !== 'production') {
  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.src = 'http://localhost:35729/livereload.js';
  document.body.appendChild(s);
}
